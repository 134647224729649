<script setup>
const { t, locale } = useI18n();
const localePath = useLocalePath();
const props = defineProps(['contacts', 'groupNames']);

const contactsGroupName = computed(() => {
  // find, remove duplicates, and join to string from page.contacts.group.name
  const groupNames = props.contacts.map((item) => (item.group ? item.group.name : ''));
  return groupNames.filter((item, index) => groupNames.indexOf(item) === index).join(', ');
});
</script>

<template>
  <div class="mt-5">
    <h2 class="heading heading--2 heading--thin">
      <template v-if="groupNames && contactsGroupName">
        {{ contactsGroupName }}
      </template>
      <template v-else>
        {{ t('global.contact-list.people') }}
      </template>
    </h2>

    <div class="mt-4 b-flex">
      <div v-for="(person, personKey) in contacts" :key="personKey" xl="6" class="people__person b-flex-child mb-4">
        <PersonStrip
          :name="person.name"
          :title="person.job"
          :link="
            localePath({
              name: 'about-people-id-slug',
              params: { id: person.id, slug: person?.slug[locale] },
            })
          "
          :image="useGetImageUrl(person.image, null, 160)"
        />
      </div>
    </div>
  </div>
</template>
